import React from "react";
import { FIRST_MILE, MITCHELL, ROWRITER } from './Experiences'
import FMLandingPage from './Pages/FMLandingPage'

/**
 * Simple mapping of experiences to expected page content
 */
export const Pages = {
    [FIRST_MILE]: <FMLandingPage />,
    [MITCHELL]: <FMLandingPage isv={MITCHELL}/>,
    [ROWRITER]: <FMLandingPage isv={ROWRITER}/>
}