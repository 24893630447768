import React, { Component } from "react";
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import { MITCHELL, ROWRITER } from "../Experiences";

const AUTO_DOWNLOAD_FAIL = 'File didn’t download automatically? Click on the download button below.'
const THANK_YOU = 'Thank you for choosing 1stMILE! Open the file and start the installation.'
const DOWNLOAD_BUTTON_TEXT = 'Download'

const isvNameMap = {
  [MITCHELL]: {
    installDescription: 'Install configuration for Mitchell1 Customers',
    softwareName: 'Manager SE'
  },
  [ROWRITER]: {
    installDescription: 'Install configuration for R.O. Writer Customers',
    softwareName: ''
  }
}

export default class FMLandingPage extends Component {
  constructor(props) {
    super(props);
    this.renderIsvApplication = this.renderIsvApplication.bind(this);
  }

  render() {
      const currentYear = new Date().getFullYear();
      return (
        <Container>
          <Row>
            <Col>
              <Jumbotron className='content no-padding flex-column'>
                <Container>
                  <Row>
                    <Col xs="6" className='no-padding'>
                      <img src={`${process.env.PUBLIC_URL}/images/misc/mechanic-hand.png`} alt='mechanic-hand' className='mechanic-hand-img'/>
                    </Col>
                    <Col xs="6" className='details-group'>
                      <Container className='no-padding h-100 d-flex flex-column'>
                        <Row>
                          <Col className='no-padding'>
                            <img className='fm-logo' src={`${process.env.PUBLIC_URL}/images/first-mile/logo.svg`} alt='fm-logo'/>                                
                          </Col>
                        </Row>
                        <Row>
                          <Col className='no-padding'>
                            <p className="page-title">{THANK_YOU}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className='no-padding'>
                            <p className="paragraph-detail">{AUTO_DOWNLOAD_FAIL}</p>
                          </Col>
                        </Row>
                        {this.renderIsvApplication()}
                        <Row>
                          <Col className='no-padding'>
                            <a href="./FM-Installer-App.exe" download="FM-Installer-App.exe">
                              <button className="button">{DOWNLOAD_BUTTON_TEXT}</button>
                            </a>
                            <iframe src="/FM-Installer-App.exe" title="test" className="downloader-frame">
                            </iframe>
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-end flex-grow-1">
                          <Col className="no-padding">
                            <p className="paragraph-copyright">{`© ${currentYear} 1stMILE, LLC`}</p>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
      );
  }

  /**
   * If rendering the page specific to an ISV, render content that provides some detail about the
   * ISV
   */
  renderIsvApplication() {
    if (this.props.isv) {
      return (
        <Row className="isv-descrpition-group">
          <Col className="no-padding">
            <Container>
              <Row>
                <Col className="no-padding">
                  <p className="sub-content-title">{isvNameMap[this.props.isv].installDescription}</p>
                </Col>
              </Row>
              <Row>
                <Col className="no-padding">
                  <p className="paragraph">{isvNameMap[this.props.isv].softwareName}</p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      );
    }
    return null;
  }
}