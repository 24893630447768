import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { Router, Switch, Route } from "react-router-dom";
import { EXPERIENCES, FIRST_MILE } from "./Experiences";

import './App.css';
import { Pages } from "./PageMap";

const history = createBrowserHistory();
const defaultExperience = FIRST_MILE

export default class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      experience: '',
      experienceSet: false,
    }

    this.LandingPage = this.LandingPage.bind(this);
  }

  /**
   * Component that is rendered when a route matches the path of the landing page
   * @param {object} match route details
   */
  LandingPage({match}) {
    let currentExperience = this.state.experience;
    if (!this.state.experienceSet) {
      let isv = match.params.isv;
      if (!isv || EXPERIENCES.indexOf(isv.toLowerCase()) === -1) {
        isv = defaultExperience;
      }
      else {
        isv = isv.toLowerCase();
      }
      this.setState({experience: isv, experienceSet: true});
      document.body.className = isv;
      currentExperience = isv;
    }

    return Pages[currentExperience]
  }

  render() {
    return (
      <div className={this.state.experience}>
        <Router history={history}>
          <Switch>
            <Route 
              path = {'/:isv'}
              component = {this.LandingPage}
            />
            <Route 
              path = {'/'}
              component = {this.LandingPage}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}